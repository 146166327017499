import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a989cef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "flex xs12" }
const _hoisted_5 = { class: "flex xs12" }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_avatar = _resolveComponent("va-avatar")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_PositionSubscription = _resolveComponent("PositionSubscription")!
  const _component_va_list_item = _resolveComponent("va-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_va_list_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_va_list_item_section, { avatar: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_va_avatar, {
              src: _ctx.logo,
              size: 25
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createVNode(_component_va_list_item_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_va_list_item_label, null, {
              default: _withCtx(() => [
                _createVNode(_component_va_icon, {
                  name: _ctx.typeIcon,
                  size: 16
                }, null, 8, ["name"]),
                _createTextVNode(" " + _toDisplayString(_ctx.name) + " $" + _toDisplayString(_ctx.value), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_va_list_item_label, { caption: "" }, {
              default: _withCtx(() => [
                (_ctx.lp)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.prettyNumber(_ctx.token1.balance)) + " @ $" + _toDisplayString(_ctx.prettyNumber(_ctx.token1.price)), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.prettyNumber(_ctx.token0.balance)) + " @ $" + _toDisplayString(_ctx.prettyNumber(_ctx.token0.price)), 1)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.balance) + " @ $" + _toDisplayString(_ctx.price), 1))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.showSubscription)
          ? (_openBlock(), _createBlock(_component_va_list_item_section, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_PositionSubscription, { position: _ctx.position }, null, 8, ["position"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}