
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  ref,
} from "vue";
import Moralis from "moralis";
import { NotifiUser } from "@/models/NotifiUser";
import UserWallets from "@/components/UserWallets.vue";
import { Protocol } from "@/models/Protocol";

export default defineComponent({
  name: "ProtocolClaim",
  components: { UserWallets },
  props: {
    protocol: { type: Protocol, required: true },
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const user: NotifiUser | undefined = inject("user");
    const app = getCurrentInstance();
    const vaToast = app?.appContext.config.globalProperties.$vaToast;
    const showToast = vaToast.init;

    const discordUser = ref("");
    const discordServer = ref("");
    const name = ref("");

    const saveValid = computed((): boolean => {
      return (
        discordUser.value != undefined &&
        discordUser.value.length > 3 &&
        discordServer.value != undefined &&
        discordServer.value.length > 3 &&
        name.value != undefined &&
        name.value.length > 3
      );
    });
    const save = async (): Promise<void> => {
      const ps = new Moralis.Query("ProtocolStatus");
      ps.equalTo("Protocol", props.protocol);
      let newPS = await ps.first();
      if (!newPS) {
        throw "Missing Protocol Info";
      }
      if (!user) {
        throw "User must be set";
      }
      newPS.set("claimStarted", true);
      newPS.set("ClaimUser", Moralis.User.current());
      newPS.set("claimName", name.value);
      newPS.set("discordUser", discordUser.value);
      newPS.set("discordServer", discordServer.value);
      try {
        newPS = await newPS.save();
        /*showToast({
          message: "Subscription added successfully!",
          color: "success",
        });*/
        emit("saved", newPS);
      } catch (error: any) {
        console.log("Claim failed -- " + error.message);
        showToast({
          message: "Claim Failed",
          color: "danger",
        });
      }
    };

    return {
      user,
      showToast,
      discordUser,
      discordServer,
      name,
      saveValid,
      save,
    };
  },
});
