import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "flex sm4"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChainPicker = _resolveComponent("ChainPicker")!
  const _component_va_input = _resolveComponent("va-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chainPrompt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ChainPicker, {
              onSelected: _ctx.selectChain,
              selectedChain: _ctx.selectedChain,
              protocol: _ctx.protocol
            }, null, 8, ["onSelected", "selectedChain", "protocol"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex", this.chainPrompt ? 'sm8' : 'sm12'])
      }, [
        _createVNode(_component_va_input, {
          label: _ctx.label,
          modelValue: _ctx.address,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address) = $event)),
          error: !_ctx.status,
          success: _ctx.status,
          "error-messages": this.err_messages,
          placeholder: "0x83952E7ab4aca74ca96217D6F8f7591BEaD6D64E"
        }, {
          appendInner: _withCtx(() => [
            (_ctx.showToken)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.status)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.contractURL,
                        target: "_blank"
                      }, _toDisplayString(_ctx.symbol), 9, _hoisted_5))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["label", "modelValue", "error", "success", "error-messages"])
      ], 2)
    ])
  ]))
}