import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex pl-2 pt-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "flex sm6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wallets, (wallet) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row pb-2",
        key: wallet
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(wallet), 1)
      ]))
    }), 128))
  ]))
}